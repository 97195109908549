var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.plant)?_c('div',[_c('v-card',{staticClass:"ma-2 mt-md-16",class:{ flipIn: _vm.isEditMode, flipOut: !_vm.isEditMode },attrs:{"max-width":"385","color":"primaryTwo","elevation":"10"}},[(!_vm.isEditMode)?[_c('div',{staticClass:"d-flex flex-column align-center justify-center ma-n10 pt-n4"},[_c('v-img',{attrs:{"width":"200","src":require("@/assets/plant-cartoon-img-sm.png")}})],1),_c('v-card-actions',{staticClass:"space card-actions"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"iconOne","icon":""},on:{"click":function($event){return _vm.editItem()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,false,316365110)},[_c('span',[_vm._v("Edit "+_vm._s(_vm.plant.plantName))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"iconTwo","icon":""},on:{"click":function($event){return _vm.goToDetails()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-notebook")])],1)]}}],null,false,407865698)},[_c('span',[_vm._v("Go to Details")])]),(_vm.plant.watch)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","icon":""},on:{"click":function($event){return _vm.removeFromWatchlist()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-star")])],1)]}}],null,false,204262030)},[_c('span',[_vm._v("Remove "+_vm._s(_vm.plant.plantName)+" from Watchlist")])]):_vm._e(),(!_vm.plant.watch)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","icon":""},on:{"click":function($event){return _vm.addToWatchlist()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-star-outline")])],1)]}}],null,false,3982103017)},[_c('span',[_vm._v("Add "+_vm._s(_vm.plant.plantName)+" to Watchlist")])]):_vm._e()],1),_c('v-card-title',{staticClass:"center card-title py-4"},[_vm._v(" "+_vm._s(_vm.plant.plantName)+" ")]),_c('v-card-text',[_c('v-row',{staticClass:"py-4",attrs:{"justify":"center"}},[_c('div',{staticClass:"text-subtitle-1"},[_vm._v(" "+_vm._s(_vm.plant.plantType)+" ")])]),_c('v-row',{staticClass:"py-5",attrs:{"justify":"center"}},[_c('div',{staticClass:"text-subtitle-1"},[_vm._v(" "+_vm._s(_vm._f("formatDate")(_vm.plant.dateAcquired))+" ")])])],1)]:_vm._e(),(_vm.isEditMode)?[_c('div',{staticClass:"d-flex flex-column align-center justify-center ma-n10 pt-n4"},[_c('v-img',{attrs:{"width":"200","src":require("@/assets/plant-cartoon-img-sm.png")}})],1),_c('v-card-actions',{staticClass:"center card-actions"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"iconOne","icon":""},on:{"click":function($event){return _vm.saveItem()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-content-save")])],1)]}}],null,false,3077510771)},[_c('span',[_vm._v("Save Changes")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"error","icon":""},on:{"click":function($event){return _vm.deleteItem()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,false,693034368)},[_c('span',[_vm._v("Delete "+_vm._s(_vm.plant.plantName))])])],1),_c('v-card-title',[_c('v-text-field',{attrs:{"rules":_vm.rules,"hide-details":"auto","label":"Plant Name","clearable":"","dense":""},model:{value:(_vm.plant.plantName),callback:function ($$v) {_vm.$set(_vm.plant, "plantName", $$v)},expression:"plant.plantName"}})],1),_c('v-card-text',[_c('v-text-field',{attrs:{"rules":_vm.rules,"hide-details":"auto","label":"Plant Type","clearable":"","dense":""},model:{value:(_vm.plant.plantType),callback:function ($$v) {_vm.$set(_vm.plant, "plantType", $$v)},expression:"plant.plantType"}}),_c('div',[[_c('vc-date-picker',{staticClass:"inline-block h-full",attrs:{"is-dark":_vm.$vuetify.theme.dark},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var inputValue = ref.inputValue;
var togglePopover = ref.togglePopover;
return [_c('div',{staticClass:"flex items-center"},[_c('v-btn',{attrs:{"icon":"","color":"accent","disabled":!_vm.isEditMode},on:{"click":function($event){return togglePopover()}}},[_c('v-icon',[_vm._v("mdi-calendar-month")])],1),_vm._v(" "+_vm._s(inputValue)+" ")],1)]}}],null,false,640271654),model:{value:(_vm.plant.dateAcquired),callback:function ($$v) {_vm.$set(_vm.plant, "dateAcquired", $$v)},expression:"plant.dateAcquired"}})]],2)],1)]:_vm._e()],2)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }