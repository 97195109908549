var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-navigation-drawer',{attrs:{"app":"","height":"100vh","color":"darken-1 primaryTwo","temporary":""},nativeOn:{"mouseleave":function($event){return _vm.toggleSidenav.apply(null, arguments)}},model:{value:(_vm.drawerState),callback:function ($$v) {_vm.drawerState=$$v},expression:"drawerState"}},[(!_vm.isAuthenticated)?_c('v-list',[_c('v-list-item',{attrs:{"disabled":_vm.isSignInRouteActive},on:{"click":function($event){return _vm.redirectToLogin()}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Sign In ")])],1)],1),_c('v-list-item',{attrs:{"disabled":_vm.isRegisterRouteActive},on:{"click":function($event){return _vm.redirectToRegister()}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Create Account")])],1)],1)],1):_vm._e(),(_vm.isAuthenticated)?_c('v-list',[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.getUser.email)+" ")])],1)],1),_c('v-divider'),_c('v-list-item',{on:{"click":function($event){return _vm.signOut()}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Sign Out ")])],1)],1),_c('router-link',{attrs:{"to":"/home/watchlist"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isExactActive = ref.isExactActive;
var navigate = ref.navigate;
return [_c('v-list-item',{staticClass:"nav-item",class:{ activeNavItem: isExactActive },on:{"click":navigate}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"nav-item-title"},[_c('v-icon',[_vm._v("mdi-view-list")]),_vm._v("Watch List ")],1)],1)],1)]}}],null,false,2220295225)}),_c('router-link',{attrs:{"to":"/home/notes"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isExactActive = ref.isExactActive;
var navigate = ref.navigate;
return [_c('v-list-item',{staticClass:"nav-item",class:{ activeNavItem: isExactActive },on:{"click":navigate}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"nav-item-title"},[_c('v-icon',[_vm._v("mdi-notebook-edit")]),_vm._v("My Notes ")],1)],1)],1)]}}],null,false,2509717999)}),_c('router-link',{attrs:{"to":"/home/plantlog"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isExactActive = ref.isExactActive;
var navigate = ref.navigate;
return [_c('v-list-item',{staticClass:"nav-item",class:{ activeNavItem: isExactActive },on:{"click":navigate}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"nav-item-title"},[_c('v-icon',[_vm._v("mdi-calendar-today")]),_vm._v(" Plant Log ")],1)],1)],1)]}}],null,false,280916875)})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }